import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import { Eco } from '@material-ui/icons';
import { Product } from '@pizza-hut-us-development/client-core';
import Badge, { SoldOutBadge } from '@/coreComponents/boxes/Badge';
import AnchorLink, { AnchorLinkProps } from '@/common/AnchorLink/AnchorLink';
import { LinkTypes } from '@/common/AnchorLink/types';
import { formatTestId } from '@/common/string-formatter';
import InfoIcon from '@/localization/icons/InfoIcon';
import InfoRail from '@/common/components/InfoRail';
import InfoRailStyles from '@/common/components/InfoRail/InfoRail.styles';
import telemetry from '@/telemetry';
import ProductImage from '@/common/components/LocalizedProductTile/ProductImage';
import useProductTileClasses from '@/common/components/LocalizedProductTile/styles';
import Routes from '@/router/routes';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import useShowNewBadge from '@/menu/hooks/useShowNewBadge';
import NewBadge from '@/coreComponents/boxes/Badge/NewBadge';
import ProductId from '@/common/ProductId';
import { useMenuTileStyles } from './MenuTile.styles';

import oldMenuTileStyles from '../../../menu/MenuTile/MenuTile.styles';
import { AddToCart } from '../../organisms/AddToCart';
import ProductDetails from '@/common/ProductDetails/ProductDetails';
import useNutritionStyles from '@/common/components/LocalizedProductTile/RenderNutritionInfo/styles';
import { getNutritionInfo } from '@/clientCore/menu/utils/pizzaNutrition';

export const MENU_TILE_TESTID = 'pizza-menu-tile';
export const NUTRITION_INFO_TESTID = 'nutrition-info';
export const TILE_NAME_TESTID = 'menu-tile-title';
export const CUSTOMIZE_BUTTON_TESTID = 'customize-button';
export const PRODUCT_INFO_RAIL_TESTID = 'product-info-rail';
export const PRODUCT_INFO_ICON_TESTID = 'product-info-icon';

const hasValidProductDefaults = (product: Product) => (product.selectedOptions ?? []).length > 0;

type ProductLinkProps = Partial<AnchorLinkProps> & {
  link: string;
};

const ProductLink = ({ children, link, ...linkProps }: ProductLinkProps) => (
  <AnchorLink
    {...linkProps}
    link={link}
    linkType={LinkTypes.RELATIVE_LINK}
    external={false}
  >
    {children}
  </AnchorLink>
);

interface MenuTileProps {
  product: Product;
  handleClick(): void;
  isCyo?: boolean;
  stackCTA?: boolean;
  isNationalStore?: boolean;
  handleAnalyticsAddToCartClick?(quantity: number): void;
  handleAnalyticsCustomizeLinkClick?(): void;
  handleAnalyticsAddToCartSuccess?(quantity: number): void;
  handleAnalyticsGetStartedClick?(): void;
}

const MenuTile = (props: MenuTileProps): JSX.Element => {
  const {
    handleClick,
    product,
    isCyo,
    stackCTA = false,
    isNationalStore = false,
    handleAnalyticsAddToCartClick,
    handleAnalyticsCustomizeLinkClick,
    handleAnalyticsAddToCartSuccess,
    handleAnalyticsGetStartedClick
  } = props;
  const {
    id,
    name,
    imageURL,
    price,
    description,
    outOfStock,
    displayOrder,
    selectedOptions
  } = product;
  const { globalId: productId } = new ProductId(id ?? '');
  const classes = oldMenuTileStyles({ outOfStock, hasLinkEnabled: !outOfStock });
  const menuTileClasses = useMenuTileStyles({ stackCTA });
  const productTileClasses = useProductTileClasses();
  const nutritionClasses = useNutritionStyles({ isMenuTile: true });
  const nutritionInfo = getNutritionInfo(selectedOptions);
  const classesInfoRail = InfoRailStyles();
  const [showInfoRail, setShowInfoRail] = useState<boolean>(false);
  const customizeLinkUrl = `${Routes.MENU.PIZZA_BUILDER}?id=${productId}`;
  const tileClassName = clsx(
    productTileClasses.grid,
    productTileClasses.gridTemplateWhenNoDropdown,
    menuTileClasses.grid
  );
  const showGetStartedButton = isNationalStore || isCyo || !hasValidProductDefaults(product);
  const { shouldShowNewBadge } = useShowNewBadge(productId);
  const badges = {
    new: shouldShowNewBadge,
    soldOut: outOfStock,
    popular: displayOrder === 0,
    vegetarian: false // todo: update when CC provides this data
  };

  let badgeComponent = null;
  if (badges.soldOut) {
    badgeComponent = <SoldOutBadge />;
  } else if (badges.new) {
    badgeComponent = <NewBadge />;
  } else if (badges.popular) {
    badgeComponent = <Badge alert text="POPULAR" />;
  } else if (badges.vegetarian) {
    badgeComponent = (
      <Eco
        className={classes.vegetarianIcon}
        aria-hidden="false"
        aria-label="vegetarian"
      />
    );
  }

  const handleInfoClick = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    telemetry.addCustomEvent(`${formatTestId(name)}-info-button-click`);
    setShowInfoRail(true);
  };

  const onHandleCTAClick = () => {
    handleClick();
    handleAnalyticsGetStartedClick?.();
  };

  const showInfoButton = !!description && !outOfStock;
  const infoRail = showInfoButton ? (
    <InfoRail id={PRODUCT_INFO_RAIL_TESTID} visible={showInfoRail} onClose={() => setShowInfoRail(false)}>
      <Box p={2}>
        <Typography component="p" className={classesInfoRail.subtitle}>
          {name}
        </Typography>
        <Typography className={classesInfoRail.description}>
          {description}
        </Typography>
      </Box>
    </InfoRail>
  ) : null;

  return (
    <Grid item className={tileClassName} data-testid={MENU_TILE_TESTID}>
      <ProductLink linkTitle={name} link={customizeLinkUrl}>
        <ProductImage
          imageUrl={imageURL ?? ''}
          description={description ?? ''}
          className={clsx(!outOfStock && menuTileClasses.imageClickable)}
        />
      </ProductLink>
      <div className={productTileClasses.priceAndNameContainer}>
        <Grid className={productTileClasses.productInfoContainer}>
          <div>
            <div data-testid="tile-badge" className={menuTileClasses.badges}>
              {badgeComponent}
            </div>
            <Typography data-testid={TILE_NAME_TESTID} className={clsx(outOfStock && classes.outOfStock, classes.title)}>
              {name}
            </Typography>
            {!!price && (
              <ProductDetails
                price={price}
                product={product}
                showPrefix
              />
            )}
          </div>
          {showInfoButton && (
            <IconButton
              data-testid={PRODUCT_INFO_ICON_TESTID}
              aria-label="Product Info"
              aria-haspopup="dialog"
              disableFocusRipple
              disableRipple
              className={productTileClasses.infoIcon}
              onClick={handleInfoClick}
            >
              <InfoIcon />
            </IconButton>
          )}
        </Grid>
      </div>
      <div className={clsx(productTileClasses.sizeDropdownAndCtaButtonWrapper, menuTileClasses.cta)}>
        {!showGetStartedButton && (
          <div className={menuTileClasses.ctaLinkWrapper}>
            <ProductLink
              linkTitle={name}
              link={customizeLinkUrl}
              className={menuTileClasses.ctaLink}
              testId={CUSTOMIZE_BUTTON_TESTID}
              onClick={handleAnalyticsCustomizeLinkClick}
            >
              <>Customize</>
            </ProductLink>
          </div>
        )}
        <div className={menuTileClasses.ctaButton}>
          {showGetStartedButton ? (
            <CtaButton
              color={isCyo ? 'primary' : 'secondary'}
              labelText="Get Started"
              size="medium"
              disabled={outOfStock}
              ariaHidden
              clickHandler={onHandleCTAClick}
            />
          ) : (
            <AddToCart
              product={product}
              disabled={outOfStock}
              handleAnalyticsAddToCartClick={handleAnalyticsAddToCartClick}
              handleAnalyticsAddToCartSuccess={handleAnalyticsAddToCartSuccess}
            />
          )}
        </div>
      </div>
      {!!nutritionInfo && (
        <Grid item data-testid={NUTRITION_INFO_TESTID} className={nutritionClasses.nutrition}>
          {nutritionInfo}
        </Grid>
      )}
      {infoRail}
    </Grid>
  );
};

export default MenuTile;
